/* --------------------------------------------------------------------------
| IMPORTS...
|-------------------------------------------------------------------------- */

import { pauseTimer, startTimer, timerElement, timerElementFrankWordSpeak } from "../variables/variables";
import { handleFetch, handleVerifyElementExists } from "./functions-helpers";

/*
|--------------------------------------------------------------------------
|-------------------------| FUNCTIONS TIMERS |------------------------------
|--------------------------------------------------------------------------
*/

export let hour = 0;
export let minute = 0;
export let second = 0;
export let counter = 0;
export var interval;
export let desc = true;

export const handleSetCounter = (seconds) => {
    counter = seconds;
    second = counter % 60;
    minute = Math.floor((counter % 3600) / 60);
    hour = Math.floor(counter / 3600)

    handleRenderTimer(hour, minute, second);
}

export const handleVerifyTimeStamp = async (uri) => {
    let data = await handleFetch(uri);
    let usigned = data.synchronizedSeconds > 0;
    let synchronizedSeconds = data.synchronizedSeconds;

    if (data.synchronizedSeconds < 0) {
        desc = false;
        synchronizedSeconds = -(data.synchronizedSeconds)
    } else {
        desc = true;
    }

    counter = synchronizedSeconds;

    return usigned;
}

// Seta o zero do lado esquerdo nos valores menores que 10...
export const handleFixZero = (time) => {
    return (time < 10) ? `0${time}` : time;
}

// Envia pro html os valores do relógio digital...
export const handleRenderTimer = (hour, minute, second) => {
    let elements = document.querySelectorAll('.timer__temp');
    elements.forEach((element) => {
        element.innerHTML = `${handleFixZero(hour)}:${handleFixZero(minute)}:${handleFixZero(second)}`;
    });

}

// Inicia o timer...
export const handleStartTimer = (type) => {
    return setInterval(() => handleCounter(type), 1000);
}

// Pausa o timer...
export const handlePauseTimer = async (interval) => {
    clearInterval(interval);
}

// Para o timer e zera...
export const handleStopTimer = (interval) => {
    clearInterval(interval);

    hour = 0;
    minute = 0;
    second = 0;
    counter = 0;

    handleRenderTimer(0, 0, 0);
}

/* --------------------------------------------------------------------------
| FUNÇÃO QUE TOCA O BEEP...
|-------------------------------------------------------------------------- */
export const handlePlayAlarm = (soundBeep) => { // Lida com tocar o som da tecla...
    let beep = document.querySelector(`#beep-${soundBeep}`);

    if (beep) {
        beep.currentTime = 0; // Seta o time para zero...
        beep.play(); // chama a função de tocar...
    }
    /*/
    if (beep !== undefined) {
        beep.then(_ => {
            beep.currentTime = 0; // Seta o time para zero...
            beep.play();
        }).catch(error => {
            // Autoplay was prevented.
            // Show a "Play" button so that user can start playback.
        });
    }*/
}

/* --------------------------------------------------------------------------
| FUNÇÃO QUE ADICIONA MAIS TEMPO NA FALA...
|-------------------------------------------------------------------------- */
export const handleAddMoretime = (time) => {
    let tempAdd = time;

    if (desc) {
        counter = counter + tempAdd;
    } else {
        /**
         * Estou mexendo aqui
         * */
        counter = counter - tempAdd;

        if(counter < 0){
            counter = -(counter);
            desc = true;
        }
    }

    handleFetch(`/frank-word/add-time/${tempAdd}/${desc ? 'desc' : 'asc'}`);
}

export const  handleSetDescStatus = (status) => {
    desc = status;
}

// Faz a contagem e verificação dos segundos para incrementar os minutos e horas...
export const handleCounter = async (type) => {

    switch (type) {
        case 'FRANK_WORD_SUBSCRIBE':
            if (counter % 3 == 0) {
                await handleVerifyTimeStamp('/frank-word/subscribe/syncronized-seconds');
            };

            second = Math.floor(++counter % 60);
            minute = Math.floor((counter % 3600) / 60);
            hour = Math.floor(counter / 3600)

            handleRenderTimer(hour, minute, second);
			break;

        case 'FRANK_WORD_CALL':

            if (desc) {
                second = Math.floor(--counter % 60);
                minute = Math.floor((counter % 3600) / 60);
                hour = Math.floor(counter / 3600);

                if(counter == 30){
                    handlePlayAlarm('short');
                }

                if (counter == 0) {
                    handlePlayAlarm('long');
                    desc = false;
                }
            } else {
                second = Math.floor(++counter % 60);
                minute = Math.floor((counter % 3600) / 60);
                hour = Math.floor(counter / 3600);
            }

            handleRenderTimer(hour, minute, second);
            break;

        case 'SPEECH_REQUEST_CALL':
            if (desc) {
                second = Math.floor(--counter % 60);
                minute = Math.floor((counter % 3600) / 60);
                hour = Math.floor(counter / 3600);

                if(counter == 30){
                    handlePlayAlarm('short');
                }

                if (counter == 0) {
                    handlePlayAlarm('long');
                    desc = false;
                }

            } else {
                second = Math.floor(++counter % 60);
                minute = Math.floor((counter % 3600) / 60);
                hour = Math.floor(counter / 3600);
            }

            handleRenderTimer(hour, minute, second);
            break;

        default:
            break;
    }
}

export async function firstVerificationTime(type){
    switch (type) {
        case 'FRANK_WORD_SUBSCRIBE':
            await handleVerifyTimeStamp('/frank-word/subscribe/syncronized-seconds');

            second = Math.floor(++counter % 60);
            minute = Math.floor((counter % 3600) / 60);
            hour = Math.floor(counter / 3600);

            handleRenderTimer(hour, minute, second);
            break;

        case 'FRANK_WORD_CALL':
            await handleVerifyTimeStamp('/frank-word/subscribe/syncronized-seconds');

            if (desc) {
                second = Math.floor(--counter % 60);
                minute = Math.floor((counter % 3600) / 60);
                hour = Math.floor(counter / 3600);

                if(counter == 30){
                    handlePlayAlarm('short');
                }

                if (counter == 0) {
                    handlePlayAlarm('long');
                    desc = false;
                }

            } else {
                second = Math.floor(++counter % 60);
                minute = Math.floor((counter % 3600) / 60);
                hour = Math.floor(counter / 3600);
            }

            handleRenderTimer(hour, minute, second);
            break;
        default:
            break;
    }
    return true;
}

export const getCounter = () => {
    return counter;
}

/* --------------------------------------------------------------------------
| FUNÇÃO QUE EXIBE O TIMER QUANDO INSCRITO JÁ FALOU...
|-------------------------------------------------------------------------- */
export const handleShowTimer = () => {
    let timer = document.querySelector('.timer');
    timer.classList.remove('none-element');
}

/* --------------------------------------------------------------------------
| FUNÇÃO QUE REMOVE O TIMER QUANDO INSCRITO JÁ FALOU...
|-------------------------------------------------------------------------- */
export const handleHiddenTimer = () => {
    let timer = document.querySelector('.timer');
    timer.classList.add('none-element');
}

/* --------------------------------------------------------------------------
| FUNÇÃO QUE REMOVE O INCRITO QUE JÁ FALOU...
|-------------------------------------------------------------------------- */
export const handleRemoveSubscribed = (element) => {
    let elementDad = element.closest('.speak-control');
    elementDad.classList.add('none-element');
}

/* --------------------------------------------------------------------------
| FUNÇÃO QUE ADICIONA A CLASSE DE "JÁ FALOU" AO INSCRITO QUE TERMINOU SUA FALA...
|-------------------------------------------------------------------------- */
export const handleRemoveSubscribedAlreadySpoke = (element) => {
    handleRemoveSubscribed(element);
    handleHiddenTimer();
}

/* --------------------------------------------------------------------------
| FUNÇÃO QUE ENCERRA A FALA DO VEREADOR DA PALAVRA FRANCA...
|-------------------------------------------------------------------------- */
export const handleStopSubscribedSpeakFrankWord = async (element, interval) => {
    handleStopTimer(interval); // Limpa o timer...
    // handleRemoveSubscribedAlreadySpoke(element); // Remove o vereador da lista de inscritos...
    let data = await handleFetch('/frank-word/councilor/stop');

    location.reload();
}

export const handlePauseSubscribedSpeakFrankWord = async (element) => {
    handlePauseTimer(interval); // Limpa o timer...
    handleFetch(`frank-word/subscribe/syncronized-seconds/pause/${counter}`);
}

export const handlePauseSpeakFrankWord = async (element) => {
    handlePauseTimer(interval); // Limpa o timer...

    if(desc){
        timerElementFrankWordSpeak.dataset.in_second = counter;
        let response = await handleFetch(`/frank-word/councilor/pause/${counter}`);
    }else{
        timerElementFrankWordSpeak.dataset.in_second = counter;
        let response = await handleFetch(`/frank-word/councilor/pause/-${counter}`);
    }
    window.location.reload();
}
