/* --------------------------------------------------------------------------
| IMPORTS...
|-------------------------------------------------------------------------- */

import { timerElement, timerElementFrankWordSubscriber } from "../../variables/variables";
import { firstVerificationTime, handleCounter, handlePauseTimer, handleSetCounter, handleSetDescStatus, handleStartTimer, handleStopTimer, handleVerifyTimeStamp } from "../../functions/functions-timers";
import { handleSelectScreensOnPanel, handleSelectScreensOnTimer } from "../../functions/functions-quoruns";
import {
	handleCloseSubscribePanel, handleInitalPauseTimerSubscribeFrankwordPanel, handleInitalStopTimerSubscribeFrankwordPanel,
	handleOpenScreenFrankWordEnrroledOnPanel,
	handleOpenScreenFrankWordSubscribeOnPanel, handleSelectCleanFrankWordEnrolledsWrapper,
	handleSelectRenderFrankWordEnrolledsWrapper, handleStartSpeakTimerPanel, handleStartSubscribeTimerPanel
} from "../../functions/functions-frank-words";
import { handleCallSpeakFrankWordPanel, handleOpenFrankWordSpeakOnPanel, handleSetInformationCallCouncilorSpeak } from "../../functions/functions-speaks";
import { handleFetch, handleVerifyElementExists } from "../../functions/functions-helpers";

let { sectionPanelFrankWordSubscribe, sectionPanelFrankWordSpeak, sectionPanelFrankWordSpeechRequest } = handleSelectScreensOnPanel();
let interval;

/* --------------------------------------------------------------------------
| EVENTS FRANK WORD PANEL...
|-------------------------------------------------------------------------- */

const timerSubscribeFrankWordPausedCouncilor = timerElementFrankWordSubscriber.dataset.in_second;
const timerSubscribeFrankWordStopedCouncilor = timerElementFrankWordSubscriber.dataset.is_stoped;

// Quando tem inscritos
// COLOQUEI UMA VERIFICAÇÃO AQUI SE EXISTE A VARIAVEL......
const hasSubs = document.querySelector('#section__panel__frank__word__enrolled');
let hasSubscribers;
handleVerifyElementExists(hasSubs, () => {
	hasSubscribers = hasSubs.dataset.has_subscribers;
});
// const hasSubscribers = document.querySelector('#section__panel__frank__word__enrolled').dataset.has_subscribers;

addEventListener('load', function () {
	let { sectionPanelFrankWordSubscribe, sectionPanelFrankWordSpeak } = handleSelectScreensOnPanel();

	// COLOQUEI UMA VERIFICAÇÃO AQUI SE EXISTE A VARIAVEL......
	handleVerifyElementExists(sectionPanelFrankWordSubscribe, () => {
		if (sectionPanelFrankWordSubscribe.dataset.subscribe_frank_word_started) {
			handleStartSubscribeTimerPanel();
		}
	});

	// COLOQUEI UMA VERIFICAÇÃO AQUI SE EXISTE A VARIAVEL......
	handleVerifyElementExists(sectionPanelFrankWordSpeak, () => {
		if (sectionPanelFrankWordSpeak.dataset.speak_frank_word_started) {
			handleStartSpeakTimerPanel();
		}
	});

	// Condição oque verifica se existe inscritos, caso existir exibe a tela de inscritos
	// COLOQUEI UMA VERIFICAÇÃO AQUI SE EXISTE A VARIAVEL......
	if (hasSubscribers
		&& handleVerifyElementExists(sectionPanelFrankWordSpeak, () => {
			!sectionPanelFrankWordSpeak.dataset.speak_frank_word_started
		})
	) {
		handleOpenScreenFrankWordEnrroledOnPanel();
	}
});

if (timerSubscribeFrankWordPausedCouncilor) {
	handleInitalPauseTimerSubscribeFrankwordPanel(timerSubscribeFrankWordPausedCouncilor)
}

if (timerSubscribeFrankWordStopedCouncilor) {
	handleInitalStopTimerSubscribeFrankwordPanel(interval);
}

// COLOQUEI UMA VERIFICAÇÃO AQUI SE EXISTE A VARIAVEL......
if (!timerElement.dataset.is_stoped
	&& !timerElement.dataset.in_second
	&& handleVerifyElementExists(sectionPanelFrankWordSpeak, () => { !sectionPanelFrankWordSpeak.dataset.speak_frank_word_started })
	&& !sectionPanelFrankWordSpeechRequest.dataset.speech_request_started
) {
	firstVerificationTime('FRANK_WORD_SUBSCRIBE'); // Possível problema...
	interval = setInterval(() => handleCounter('FRANK_WORD_SUBSCRIBE'), 1000);
}

// COLOQUEI UMA VERIFICAÇÃO AQUI SE EXISTE A VARIAVEL......
handleVerifyElementExists(sectionPanelFrankWordSpeak, () => {
	if (sectionPanelFrankWordSpeak.dataset.speak_frank_word_in_second) {
		clearInterval(interval);
		if (parseInt(sectionPanelFrankWordSpeak.dataset.speak_frank_word_in_second) < 0) {
			handleSetDescStatus(false);
			handleSetCounter(-(sectionPanelFrankWordSpeak.dataset.speak_frank_word_in_second))

		} else {
			handleSetDescStatus(true);
			handleSetCounter(sectionPanelFrankWordSpeak.dataset.speak_frank_word_in_second)
		}
	}

	if (sectionPanelFrankWordSpeak.dataset.speak_frank_word_started && !sectionPanelFrankWordSpeak.dataset.speak_frank_word_in_second) {
		handleVerifyTimeStamp('/frank-word/syncronized-seconds/request').then(function () {
			interval = setInterval(() => handleCounter('FRANK_WORD_CALL'), 1000);
		});
	}
});

// window.Echo.channel("frank-word-subscribe-started")
// 	.listen(".frank-word-subscribe-started", (e) => {
// 		handleOpenScreenFrankWordSubscribeOnPanel();
// 		clearInterval(interval);
// 		interval = setInterval(() => handleCounter('FRANK_WORD_SUBSCRIBE'), 1000);
// 	});

window.Echo.channel('frank-word-subscribe-time-restarted')
	.listen('.frank-word-subscribe-time-restarted', function () {
		window.location.reload();
	});

window.Echo.channel("frank-word-closed")
	.listen(".frank-word-closed", (e) => {
		clearInterval(interval);
		handleSetCounter(0);
		handleSelectCleanFrankWordEnrolledsWrapper();
		handleCloseSubscribePanel();

		// EXIBINDO OS INSCRITOS NO PAINEL APÓS FINALIZAR A INSCRIÇÃO (problema de dar f5)...

		// handleSelectRenderFrankWordEnrolledsWrapper(e.user);
		handleOpenScreenFrankWordEnrroledOnPanel();
		window.location.reload();
	});

window.Echo.channel("frank-word-subscribed")
	.listen(".frank-word-subscribed", (e) => {
		handleSelectRenderFrankWordEnrolledsWrapper(e.user);
	});

/** Speak */
// window.Echo.channel("frank-word-added-more-minute-in-speak")
// 	.listen(".frank-word-added-more-minute-in-speak", async (e) => {
// 		handleSetDescStatus(e.desc);
// 		handleFetch('/frank-word/subscribe/syncronized-seconds/event');
// 	});

// TODO - tentando aqui f5 painel
window.Echo.channel("frank-word-speak-councilor-called")
	.listen(".frank-word-speak-councilor-called", async (e) => {
		//handleOpenFrankWordSpeakOnPanel();
		//handleSetInformationCallCouncilorSpeak(e);
		//interval = handleCallSpeakFrankWordPanel(interval)
        location.reload();
	});

// window.Echo.channel("frank-word-speak-councilor-paused")
// 	.listen(".frank-word-speak-councilor-paused", async (e) => {
// 		clearInterval(await interval);

// 		if (parseInt(e.secondsPaused) > 0) {
// 			handleSetCounter(e.secondsPaused);
// 		} else {
// 			handleSetCounter(-e.secondsPaused);
// 		}
// 	});

window.Echo.channel("frank-word-speak-councilor-stoped")
	.listen(".frank-word-speak-councilor-stoped", async (e) => {
		clearInterval(await interval);
		handleSetCounter(0);
		location.reload();
	});

// window.Echo.channel("synchronized-time-speak")
// 	.listen(".synchronized-time-speak", (e) => {
// 		if (e.synchronizedSeconds < 0) {
// 			handleSetDescStatus(false)
// 			handleSetCounter(-(e.synchronizedSeconds));
// 		} else {
// 			handleSetDescStatus(true)
// 			handleSetCounter(e.synchronizedSeconds);
// 		}
// 	});

// window.Echo.channel("long-sound-played")
// 	.listen(".long-sound-played", (e) => {
// 		handlePlayAlarm('long');
// 	});

// window.Echo.channel("short-sound-played")
// 	.listen(".short-sound-played", (e) => {
// 		handlePlayAlarm('short');
// 	});


window.Echo.channel("new-subscribe-added")
	.listen(".new-subscribe-added", (e) => {
		window.location.reload();
	});
