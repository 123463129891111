/*
|--------------------------------------------------------------------------
|-----------------------| FUNCTIONS FRANK WORD |---------------------------
|--------------------------------------------------------------------------
*/

import { pauseTimer, startTimer } from "../variables/variables";
import { handleSelectScreensOnPanel, handleSelectScreensOnTimer } from "./functions-quoruns";
import { getCounter, handleSetCounter } from "./functions-timers";

export const handleStartSubscribeTimerControl = async () => {
    startTimer.setAttribute("disabled", "disabled");
    pauseTimer.removeAttribute("disabled");

    let response = await fetch(`/frank-word/subscribe/syncronized-seconds/restart/${getCounter()}`);
    return await response.json();
}

export const handleStartSubscribeTimerCouncilor = async () => {
    let frankWord = document.querySelector('#frank-word');
    frankWord.classList.remove('none-element');
}

export const handleCloseSubscribeCouncilor = () => {
    let frankWord = document.querySelector('#frank-word');
    frankWord.classList.add('none-element');
}

export const handleCloseSubscribeTimer = () => {
    let { sectionHome, sectionQuorum, sectionSubscribeTimer, sectionSpeakTimer } = handleSelectScreensOnTimer();
    if (sectionSubscribeTimer) {
        if (!sectionSubscribeTimer.classList.contains('none-element')) {

            sectionQuorum.classList.add('none-element');
            sectionSubscribeTimer.classList.add('none-element');
            sectionSpeakTimer.classList.add('none-element');
            sectionHome.classList.remove('none-element');
        }
    }

}

export const handleStartSubscribeTimerTimer = async () => {
    let { sectionHome, sectionQuorum, sectionSubscribeTimer, sectionSpeakTimer } = handleSelectScreensOnTimer();

    if (sectionSubscribeTimer) {
        if (sectionSubscribeTimer.classList.contains('none-element')) {
            sectionHome.classList.add('none-element');
            sectionQuorum.classList.add('none-element');
            sectionSpeakTimer.classList.add('none-element');
            sectionSubscribeTimer.classList.remove('none-element');
        }
    }

}

export const handleStartSpeakTimerTimer = async () => {
    let { sectionHome, sectionQuorum, sectionSubscribeTimer, sectionSpeakTimer } = handleSelectScreensOnTimer();

    if (sectionSubscribeTimer) {
        if (sectionSubscribeTimer.classList.contains('none-element')) {
            sectionHome.classList.add('none-element');
            sectionQuorum.classList.add('none-element');
            sectionSubscribeTimer.classList.add('none-element');
            sectionSpeakTimer.classList.remove('none-element');
        }
    }

}

export const handleInitalStopTimerSubscribeFrankwordTimer = (interval) => {
    clearInterval(interval);
    handleSetCounter(0);
}

export const handleInitalPauseTimerSubscribeFrankwordTimer = (timerSubscribeFrankWordPausedCouncilor) => {
    handleSetCounter(timerSubscribeFrankWordPausedCouncilor);
}

/* --------------------------------------------------------------------------
| FUNCTION -> RENDERIZA O INSCRITO NA PALAVRA FRANCA...
|-------------------------------------------------------------------------- */

export const handleSelectRenderFrankWordEnrolledsWrapper = (element) => {
    let enrrolledWrapper = document.querySelector('#councilorsEnrroleds');
    if (enrrolledWrapper) {
        enrrolledWrapper.innerHTML += handleRenderCouncilorEnrolledFrankWord(element);
    }
}

export const handleSelectCleanFrankWordEnrolledsWrapper = () => {
    let enrrolledWrapper = document.querySelector('#councilorsEnrroleds');
    if (enrrolledWrapper) {
        enrrolledWrapper.innerHTML = '';
    }

}

/* --------------------------------------------------------------------------
| FUNCTION -> RENDERIZA O INSCRITO NA PALAVRA FRANCA...
|-------------------------------------------------------------------------- */
export const handleRenderCouncilorEnrolledFrankWord = (enrolled) => {
    let councilorEnrolledFranWord = `
        <article class="card-person-horizontal-small" id="enrolled-control-${enrolled.id}">
            <div class="card-person-horizontal-small__avatar">
                <img src="${enrolled.user_relationship_photo
            ? enrolled.user_relationship_photo.path
            : '/assets/images/avatars/avatar.png'
        }"
                    alt="${enrolled.user_relationship_photo
            ? enrolled.name
            : 'Foto de usuário fictícia, apeans a titulo de indicação que existe uma pessoa ali, um usuário, desenho de uma pessoa para representar o usuário quando o mesmo não possui uma imagem própria'
        }"
                    title="${enrolled.name}"x'
                />
            </div>

            <div class="card-person-horizontal-small__infos">
				<div class="card-person-horizontal-small__infos__text"> ${enrolled.name} </div>
			</div>
		</article>
	`;
    // ${enrolled.userInformationRelationship.informationRelationshipPoliticalParty &&
    // 	<div class="card-person-horizontal-small__infos__text">
    // 		<img class="card-person-avatar-political-party"
    // 			src={enrolled.userInformationRelationship.informationRelationshipPoliticalParty.photoRelationship.path
    // 				? enrolled.userInformationRelationship.informationRelationshipPoliticalParty.photoRelationship.path
    // 				: '/assets/images/brasao.jpeg'
    // 			}
    // 			alt={enrolled.userInformationRelationship.informationRelationshipPoliticalParty.acronym
    // 				? enrolled.userInformationRelationship.informationRelationshipPoliticalParty.acronym
    // 				: 'Câmara Capelinha'
    // 			}
    // 			title={enrolled.userInformationRelationship.informationRelationshipPoliticalParty.acronym
    // 				? enrolled.userInformationRelationship.informationRelationshipPoliticalParty.acronym
    // 				: 'Câmara Capelinha'
    // 			}
    // 		/>
    // 	</div>
    // }

    return (councilorEnrolledFranWord) ? councilorEnrolledFranWord : '';
}

export const handleOpenScreenFrankWordSubscribeOnPanel = () => {
    let { sectionPanelHome, sectionPanelPropositionRead, sectionPanelPropositionVote, sectionPanelQuorum, sectionPanelFrankWordSubscribe, sectionPanelFrankWordEnrroled } = handleSelectScreensOnPanel();

    if (sectionPanelFrankWordSubscribe) {
        if (sectionPanelFrankWordSubscribe.classList.contains('none-element')) {
            sectionPanelHome.classList.add('none-element');
            sectionPanelPropositionRead.classList.add('none-element');
            sectionPanelPropositionVote.classList.add('none-element');
            sectionPanelQuorum.classList.add('none-element');
            sectionPanelFrankWordEnrroled.classList.add('none-element');
            sectionPanelFrankWordSubscribe.classList.remove('none-element');
        }
    }

}

export const handleCloseSubscribePanel = () => {
    let { sectionPanelHome, sectionPanelPropositionRead, sectionPanelPropositionVote, sectionPanelQuorum, sectionPanelFrankWordSubscribe, sectionPanelFrankWordEnrroled } = handleSelectScreensOnPanel();

    if (sectionPanelFrankWordSubscribe) {
        if (!sectionPanelFrankWordSubscribe.classList.contains('none-element')) {
            sectionPanelPropositionRead.classList.add('none-element');
            sectionPanelPropositionVote.classList.add('none-element');
            sectionPanelQuorum.classList.add('none-element');
            sectionPanelFrankWordSubscribe.classList.add('none-element');
            sectionPanelFrankWordEnrroled.classList.add('none-element');
            sectionPanelHome.classList.remove('none-element');
        }
    }

}

export const handleOpenScreenFrankWordEnrroledOnPanel = () => {
    let { sectionPanelHome, sectionPanelPropositionRead, sectionPanelPropositionVote, sectionPanelQuorum, sectionPanelFrankWordEnrroled } = handleSelectScreensOnPanel();

    if (sectionPanelFrankWordEnrroled) {
        if (sectionPanelFrankWordEnrroled.classList.contains('none-element')) {
            sectionPanelHome.classList.add('none-element');
            sectionPanelPropositionRead.classList.add('none-element');
            sectionPanelPropositionVote.classList.add('none-element');
            sectionPanelQuorum.classList.add('none-element');
            // sectionPanelFrankWordSubscribe.classList.add('none-element');
            sectionPanelFrankWordEnrroled.classList.remove('none-element');
        }
    }

}

export const handleInitalStopTimerSubscribeFrankwordPanel = (interval) => {
    clearInterval(interval);
    handleSetCounter(0);
}

export const handleInitalPauseTimerSubscribeFrankwordPanel = (timerSubscribeFrankWordPausedCouncilor) => {
    handleSetCounter(timerSubscribeFrankWordPausedCouncilor);
}

export const handleStartSubscribeTimerPanel = async () => {
    let { sectionPanelHome, sectionPanelPropositionRead, sectionPanelPropositionVote, sectionPanelQuorum, sectionPanelFrankWordSubscribe, sectionPanelFrankWordSpeak } = handleSelectScreensOnPanel();

    if (sectionPanelFrankWordSubscribe) {
        if (sectionPanelFrankWordSubscribe.classList.contains('none-element')) {
            sectionPanelHome.classList.add('none-element');
            sectionPanelPropositionRead.classList.add('none-element');
            sectionPanelPropositionVote.classList.add('none-element');
            sectionPanelQuorum.classList.add('none-element');
            sectionPanelFrankWordSpeak.classList.add('none-element');
            sectionPanelFrankWordSubscribe.classList.remove('none-element');
        }
    }

}

export const handleStartSpeakTimerPanel = async () => {
    let { sectionPanelHome, sectionPanelPropositionRead, sectionPanelPropositionVote, sectionPanelQuorum, sectionPanelFrankWordSubscribe, sectionPanelFrankWordSpeak } = handleSelectScreensOnPanel();

    if (sectionPanelFrankWordSpeak) {
        if (sectionPanelFrankWordSpeak.classList.contains('none-element')) {
            sectionPanelHome.classList.add('none-element');
            sectionPanelPropositionRead.classList.add('none-element');
            sectionPanelPropositionVote.classList.add('none-element');
            sectionPanelQuorum.classList.add('none-element');
            // sectionPanelFrankWordSubscribe.classList.add('none-element');
            sectionPanelFrankWordSpeak.classList.remove('none-element');
        }
    }

}

export const handleStartSpeechRequestTimerPanel = async () => {
    let { sectionPanelHome, sectionPanelPropositionRead, sectionPanelPropositionVote, sectionPanelQuorum, sectionPanelFrankWordSubscribe, sectionPanelFrankWordSpeak, sectionPanelFrankWordSpeechRequest } = handleSelectScreensOnPanel();

    if (sectionPanelFrankWordSpeak) {
        if (sectionPanelFrankWordSpeak.classList.contains('none-element')) {
            sectionPanelHome && sectionPanelHome.classList.add('none-element');
            sectionPanelPropositionRead && sectionPanelPropositionRead.classList.add('none-element');
            sectionPanelPropositionVote && sectionPanelPropositionVote.classList.add('none-element');
            sectionPanelQuorum && sectionPanelQuorum.classList.add('none-element');
            sectionPanelFrankWordSubscribe && sectionPanelFrankWordSubscribe.classList.add('none-element');
            sectionPanelFrankWordSpeak && sectionPanelFrankWordSpeak.classList.add('none-element');
            sectionPanelFrankWordSpeechRequest && sectionPanelFrankWordSpeechRequest.classList.remove('none-element');
        }
    }

}

/* --------------------------------------------------------------------------
| BOTÃO QUE FAZ REQUISIÇÃO PARA O BACKEND INFORMANDO QUAL ORDEM DEVE SER USADA...
|-------------------------------------------------------------------------- */
export const handleFetchGetOrderFrankWord = async (type) => {
    let response = await fetch(`/frank-word/frank-word/type-chosen/${type}`);
    let responseJson = await response.json();

    window.location.replace('/frank-word/play');
}

/* --------------------------------------------------------------------------
| INCRITO DA VEZ COM ACTIVE...
|-------------------------------------------------------------------------- */
export const handleSpeakControlFrankWordSelected = (event) => {
    let element = event.currentTarget;
    let elementDad = element.closest('.speak-control--element');

    (elementDad.classList.contains('speak-control--active'))
        ? elementDad.classList.remove('speak-control--active')
        : elementDad.classList.add('speak-control--active');
}
