/* --------------------------------------------------------------------------
| VARIABLES -> QUÓRUM
|-------------------------------------------------------------------------- */

// Varivael de inicialização do contador de presença...
export var membersConfirmationCount = 0;

// Array de membros confirmados... Criado para salvar no localStorage...
export var membersConfirmationQuorum = [];

// EVENTO DO SUBMIT DE CONFIRMAÇÃO DO VEREADOR NO QUORUM...
export const memberPresenceConfirmation = document.querySelector('#councilorConfirmationElement');

// ELEMENTO PARA CANCELAMENTO DO QUORUM...
export const buttonCancelQuorum = document.querySelectorAll('.buttonCancelQuorum');

// ELMEENTO DE ENCERRAMENTO DO QUORUM...
export const buttonClosureQuorum = document.querySelector('#buttonQuorumEncerramento');

/* --------------------------------------------------------------------------
| VARIABLES -> VOTE...
|-------------------------------------------------------------------------- */
export let voteButtons = document.querySelectorAll('.vote_button');

export const COLORS = {
    in_favor: '#7FBA7A',
    against: '#E87176',
    abstain: '#1774FF',
}

/* --------------------------------------------------------------------------
| VARIABLES -> TIMER...
|-------------------------------------------------------------------------- */

export let timerElement = document.querySelector('.timer__temp');
export let timerElementFrankWordSubscriber = document.querySelector('#timer_temp_frank_word_subscriber');
export let timerElementFrankWordSpeak = document.querySelector('#timer_temp_frank_word_speak');
export const startTimer = document.querySelector('#start-timer');
export const pauseTimer = document.querySelector('#pause-timer');
export const stopTimer = document.querySelector('#stop-timer');

export let counter = 0;
export let second = 0;
export let minute = 0;
export let hour = 0;

/* --------------------------------------------------------------------------
| FILE BREAKPOINTS VARIABLES
|-------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------
| VARIABLES -> BREAKPOINTS PARA MEDIA QUERIES...
|-------------------------------------------------------------------------- */
// const laptopLG = '1140px';
// const laptopMD = '1200px';
// const laptopXS = '1080px';
// const laptop = '1024px';
// const tablet = '768px';
// const tabletSM = '720px';
// const mobileLG = '425px';
// const mobileMD = '375px';
// const mobileSM = '320px';

// /* --------------------------------------------------------------------------
// | VARIABLES -> MEDIA QUERIE MAX-WIDTH...
// |-------------------------------------------------------------------------- */
// export const MQ_LaptopLG_MAX = window.matchMedia(`(max-width: ${laptopLG})`);
// export const MQ_LaptopMD_MAX = window.matchMedia(`(max-width: ${laptopMD})`);
// export const MQ_LaptopXS_MAX = window.matchMedia(`(max-width: ${laptopXS})`);
// export const MQ_Laptop_MAX = window.matchMedia(`(max-width: ${laptop})`);
// export const MQ_Tablet_MAX = window.matchMedia(`(max-width: ${tablet})`);
// export const MQ_TabletSM_MAX = window.matchMedia(`(max-width: ${tabletSM})`);
// export const MQ_MobileLG_MAX = window.matchMedia(`(max-width: ${mobileLG})`);
// export const MQ_MobileMD_MAX = window.matchMedia(`(max-width: ${mobileMD})`);
// export const MQ_MobileSM_MAX = window.matchMedia(`(max-width: ${mobileSM})`);

// /* --------------------------------------------------------------------------
// | VARIABLES -> EDIA QUERIE MIN-WIDTH...
// |-------------------------------------------------------------------------- */
// export const MQ_LaptopLG_MIN = window.matchMedia(`(min-width: ${laptopLG})`);
// export const MQ_LaptopMD_MIN = window.matchMedia(`(min-width: ${laptopMD})`);
// export const MQ_LaptopXS_MIN = window.matchMedia(`(min-width: ${laptopXS})`);
// export const MQ_Laptop_MIN = window.matchMedia(`(min-width: ${laptop})`);
// export const MQ_Tablet_MIN = window.matchMedia(`(min-width: ${tablet})`);
// export const MQ_TabletSM_MIN = window.matchMedia(`(min-width: ${tabletSM})`);
// export const MQ_MobileLG_MIN = window.matchMedia(`(min-width: ${mobileLG})`);
// export const MQ_MobileMD_MIN = window.matchMedia(`(min-width: ${mobileMD})`);
// export const MQ_MobileSM_MIN = window.matchMedia(`(min-width: ${mobileSM})`);

