/* --------------------------------------------------------------------------
| IMPORTS...
|-------------------------------------------------------------------------- */

import { isEmpty } from 'lodash';
import { membersConfirmationQuorum } from '../variables/variables';
import { handleFetch, handleVerifyElementExists } from './functions-helpers';

/*
|--------------------------------------------------------------------------
|------------------------| FUNCTIONS QUORUNS |-----------------------------
|--------------------------------------------------------------------------
*/

/* --------------------------------------------------------------------------
| FUNCTION -> ADICIONA O BOTÃO DO QUORUM NO MENU DO VEREADOR E NO LOCALSTORAGE...
|-------------------------------------------------------------------------- */
export const handleAddButtonSidebarQuorumMember = () => {
    let quorumConfirmation = document.querySelector('#quorum_confirmation'); // Seleciona o elemento...

    handleVerifyElementExists(quorumConfirmation, () => {
        quorumConfirmation.classList.remove('none-element'); // Remove a classe com display none...
        quorumConfirmation.querySelector('button').removeAttribute("disabled"); // Remove o atributo disabled...
    });
    localStorage.setItem('quorumStarted', true); // Adiciona o item no localstorage...
}

/* --------------------------------------------------------------------------
| FUNCTION -> REMOVE O BOTÃO DO QUORUM NO MENU DO VEREADOR E DO LOCALSTORAGE...
|-------------------------------------------------------------------------- */
export const handleRemoveButtonSidebarQuorumMember = () => {
    let quorumConfirmation = document.querySelector('#quorum_confirmation'); // Seleciona o elemento...

    handleVerifyElementExists(quorumConfirmation, () => {
        quorumConfirmation.classList.add('none-element'); // Adiciona a classe com display none...
        quorumConfirmation.querySelector('button').setAttribute("disabled", "disabled"); // Adiciona o atributo disabled...
    });
    localStorage.removeItem('quorumStarted'); // Remove o item do localstorage...
}

/* --------------------------------------------------------------------------
| FUNCTION -> CONFIRMA A PRESENÇA DO VEREADOR NO QUORUM...
|-------------------------------------------------------------------------- */
export const handleButtonSidebarPresenceConfirmationMember = (event) => {
    event.preventDefault();
    handleRemoveButtonSidebarQuorumMember(); // Remove o botão do sidebar...

    let quorumConfirmationForm = document.querySelector('#quorum_confirmation form'); // Seleciona o form do button de confirmação...
    quorumConfirmationForm.submit(); // Envia o form com a presença...
}

/* --------------------------------------------------------------------------
| FUNCTION -> UM TIPO DE WRAPPER, CHAMA TODAS AS FUNÇÕES QUE TEM RELAÇÃO COM A CONFIRMAÇÃO DE PRESENÇA DO VEREADOR...
|-------------------------------------------------------------------------- */
export const handlePresenceConfirmation = (memberId) => {
    handlePresenceConfirmationBackgroundCard(memberId); // Background do card...
    handlePresenceConfirmationCheckInput(memberId); // Checked no input...
    handlePresenceConfirmationLocalStorage(memberId); // Salva no localStorage...
    handlePresenceConfirmationCount(); // Incrementa o contador...
}

/* --------------------------------------------------------------------------
| FUNCTION -> TROCA A COR DO CARD DO VEREADOR QUANDO ELE CONFIRMA PRESENÇA...
|-------------------------------------------------------------------------- */
export const handlePresenceConfirmationBackgroundCard = (memberId) => {
    let councilor = document.querySelector(`#quorum-member-${memberId}`);
    councilor.style.background = '#ff8864';
}

/* --------------------------------------------------------------------------
| FUNCTION -> ADICIONA O CHECKD NO INPUT CHECKBOX DO VEREADOR CONFIRMADO...
|-------------------------------------------------------------------------- */
export const handlePresenceConfirmationCheckInput = (id) => {
    let input = document.querySelector(`#memberCheckbox_${id}`);

    if (input) {
        input.checked = true;
    }
}

/* --------------------------------------------------------------------------
| FUNCTION -> SALVA OS MEMBROS CONFIRMADOS EM UM ARRAY NO LOCALSTORAGE...
|-------------------------------------------------------------------------- */
export const handlePresenceConfirmationLocalStorage = (id) => {
    let localStorageMembers = localStorage.getItem('membersPresenceConfirmationQuorum'); // Pega o array do localStorage...

    if (localStorageMembers != null) {
        let membersConfirmationQuorum = JSON.parse(localStorageMembers); // Seta no arrau com os valores do localStorage..
    }

    membersConfirmationQuorum.push(id); // Adiciona membro ao array...

    let membersConfirmationJson = JSON.stringify(membersConfirmationQuorum); // Converte para json...
    localStorage.setItem('membersPresenceConfirmationQuorum', membersConfirmationJson); // Seta no array do localStorage...
}

/* --------------------------------------------------------------------------
| FUNCTION -> CONTADOR DE PRESENÇAS CONFIRMADAS...
|-------------------------------------------------------------------------- */
export const handlePresenceConfirmationCount = async () => {
    let data = await handleFetch('/quorum/get-members-presents');
    let membersPresenceConfirmationConvert = data.membersPresents;
    let membersPresenceConfirmationCount = membersPresenceConfirmationConvert.length;
    let badge = document.querySelector('#countPresenceMembers');

    badge.innerHTML = membersPresenceConfirmationCount;
}

/* --------------------------------------------------------------------------
| FUNCTION -> CANCELA O QUORUM LIMPANDO O LOCALSTORAGE...
|-------------------------------------------------------------------------- */
export const handleCancelQuorum = () => {
    localStorage.removeItem('quorumStarted');
    localStorage.removeItem('membersPresenceConfirmationQuorum');
}

/* --------------------------------------------------------------------------
| FUNCTION -> QUANDO ENCERRA O QUORUM, DA UM UPDATE NO BANCO...
|-------------------------------------------------------------------------- */
export const handleCloseQuorum = (event) => {
    event.preventDefault();

    let confirmedMembers = localStorage.getItem('membersPresenceConfirmationQuorum');
    let confirmedMembersConvert = JSON.parse(confirmedMembers);
    let quorumClosure = document.querySelector('#quorumEncerramento'); // Seleciona o form de encerramento do quorum...

    localStorage.removeItem('membersPresenceConfirmationQuorum')
    quorumClosure.submit(); // Envia o form...
}

/* --------------------------------------------------------------------------
| FUNCTION -> CONSULTA OS VEREADORES CONFIRMADOS NO LOCALSTORAGE E ALTERA NO HTML...
|-------------------------------------------------------------------------- */
export const handlePreloadMembersConfirmed = async () => {
    let data = await handleFetch('/quorum/get-members-presents');
    let membersPresence = data.membersPresents


    if (membersPresence) {
        membersPresence.forEach((memberId) => {
            handlePresenceConfirmation(memberId);
        });
    }
}

/* --------------------------------------------------------------------------
| FUNCTION -> SELECIONA AS VIEWS DO MÓDULO TIMER...
|-------------------------------------------------------------------------- */
export const handleSelectScreensOnTimer = () => {
    let sectionHome = document.querySelector('#section__timer__home');
    let sectionQuorum = document.querySelector('#section__timer__quorum');
    let sectionSubscribeTimer = document.querySelector('#section__timer__frank__word_subscribe');
    let sectionSpeakTimer = document.querySelector('#section__timer__speak');
    let sectionPanelFrankWordSpeechRequest = document.querySelector('#section__panel__speech__request')

    return { sectionHome, sectionQuorum, sectionSubscribeTimer, sectionSpeakTimer, sectionPanelFrankWordSpeechRequest };
}

/* --------------------------------------------------------------------------
| FUNCTION -> ABRE A VIEW DE QUORUM NO MÓDULO TIMER...
|-------------------------------------------------------------------------- */
export const handleOpenScreenQuorumOnTimer = () => {
    let { sectionHome, sectionQuorum, sectionSubscribeTimer } = handleSelectScreensOnTimer();

    if (sectionQuorum.classList.contains('none-element')) {
        localStorage.setItem('quorumStarted', true);

        sectionSubscribeTimer.classList.add('none-element')
        sectionHome.classList.add('none-element');
        sectionQuorum.classList.remove('none-element');
    }
}

/* --------------------------------------------------------------------------
| FUNCTION -> FECHAR TELA DO QUORUM NO MÓDULO TIMER...
|-------------------------------------------------------------------------- */
export const handleCloseScreenOpenSpeechRequestOnTimer = () => {
    let { sectionHome, sectionQuorum, sectionSubscribeTimer, sectionPanelFrankWordSpeechRequest } = handleSelectScreensOnTimer();

    if (sectionPanelFrankWordSpeechRequest.classList.contains('none-element')) {
        sectionHome.classList.add('none-element');
        sectionSubscribeTimer.classList.add('none-element');
        sectionQuorum.classList.add('none-element');
        sectionPanelFrankWordSpeechRequest.classList.remove('none-element');
    }
}

export const handleCloseScreenQuorumOnTimer = () => {
    let { sectionHome, sectionQuorum, sectionSubscribeTimer } = handleSelectScreensOnTimer();

    if (!sectionQuorum.classList.contains('none-element')) {
        sectionSubscribeTimer.classList.add('none-element');
        sectionQuorum.classList.add('none-element');
        sectionHome.classList.remove('none-element');

        localStorage.removeItem('quorumStarted');
    }
}


/* --------------------------------------------------------------------------
| FUNCTION -> SELECIONA AS VIEWS DO MÓDULO PANEL...
|-------------------------------------------------------------------------- */
export const handleSelectScreensOnPanel = () => {
    let sectionPanelHome = document.querySelector('#section__panel__home');
    let sectionPanelPropositionRead = document.querySelector('#section__panel__proposition__read');
    let sectionPanelPropositionVote = document.querySelector('#section__panel__proposition__vote');
    let sectionPanelQuorum = document.querySelector('#section__panel__quorum');
    let sectionPanelFrankWordSubscribe = document.querySelector('#section__panel__frank__word__subscribe');
    let sectionPanelFrankWordEnrroled = document.querySelector('#section__panel__frank__word__enrolled');
    let sectionPanelFrankWordSpeak = document.querySelector('#section__panel__speak');
    let sectionPanelFrankWordSpeechRequest = document.querySelector('#section__panel__speech__request');

    return {
        sectionPanelHome,
        sectionPanelQuorum,
        sectionPanelPropositionRead, sectionPanelPropositionVote,
        sectionPanelFrankWordEnrroled, sectionPanelFrankWordSpeak, sectionPanelFrankWordSpeechRequest
    };
}

/* --------------------------------------------------------------------------
| FUNCTION -> ABRE A VIEW DE QUORUM NO MÓDULO PANEL...
|-------------------------------------------------------------------------- */
export const handleOpenScreenQuorumOnPanel = () => {
    let { sectionPanelHome, sectionPanelPropositionRead, sectionPanelPropositionVote, sectionPanelQuorum } = handleSelectScreensOnPanel();

    if (sectionPanelQuorum.classList.contains('none-element')) {
        localStorage.setItem('quorumStarted', true);

        sectionPanelHome.classList.add('none-element');
        sectionPanelPropositionRead.classList.add('none-element');
        sectionPanelPropositionVote.classList.add('none-element');
        sectionPanelQuorum.classList.remove('none-element');
    }
}

/* --------------------------------------------------------------------------
| FUNCTION -> FECHAR TELA DO QUORUM NO MÓDULO PANEL...
|-------------------------------------------------------------------------- */
export const handleCloseScreenQuorumOnPanel = () => {
    let { sectionPanelHome, sectionPanelPropositionRead, sectionPanelPropositionVote, sectionPanelQuorum } = handleSelectScreensOnPanel();

    if (!sectionPanelQuorum.classList.contains('none-element')) {
        sectionPanelQuorum.classList.add('none-element');
        sectionPanelPropositionRead.classList.add('none-element');
        sectionPanelPropositionVote.classList.add('none-element');
        sectionPanelHome.classList.remove('none-element');

        localStorage.removeItem('quorumStarted');
    }
}

/* --------------------------------------------------------------------------
| FUNCTION -> ADICIONA UM BADGE DE ATIVO NO ITEM QUORUM DO MENU DO CONTROLE...
|-------------------------------------------------------------------------- */
export const handleActiveBadge = () => {
    let menuQuorum = document.querySelector('#menu__quorum');

    menuQuorum.innerHTML += `
        <span class="badge badge--quaternary badge--small">
            <span class="badge__info"> Ativo </span>
        </span>
    `;
}
